import axios from "axios";
import { backendUrls } from "../config/backendUrls";
import DevLoggingService from "./devlogging.service";
import { VideoResponseItem } from "../types/videoResponseItem";
import { CompanyResponseItem } from "../types/companyResponseItem";

class DataService {
  private log = DevLoggingService;

  async getFilteredVideos(applicationName: string, videoName: string, companyId: number): Promise<VideoResponseItem[]> {
    const logPrefix = "getFilteredVideos: ";
    this.log.writeLog(logPrefix + "Starting to fetch filtered videos");

    const url = `${process.env.REACT_APP_OASEBACKEND_API_URL}${
      backendUrls.getFilteredVideos
    }?applicationName=${encodeURIComponent(applicationName)}&videoName=${encodeURIComponent(videoName)}&companyId=${encodeURIComponent(companyId)}`;
    this.log.writeLog(logPrefix + "URL: " + url);

    const accessToken = localStorage.getItem("loginToken");
    if (!accessToken) {
      this.log.writeLog(logPrefix + "No access token available.");
      throw new Error("Authentication required");
    }

    try {
      const response = await axios.get<VideoResponseItem[]>(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.log.writeLog(logPrefix + "Successfully fetched filtered videos.");
      return response.data;
    } catch (error) {
      this.log.writeLog(logPrefix + "Error fetching filtered videos: " + error.message);
      throw error;
    }
  }

  async getCompanyById(companyId: number): Promise<CompanyResponseItem | null> {
    const logPrefix = "getCompanyById: ";
    this.log.writeLog(logPrefix + "Starting to fetch company by ID");

    const url = `${process.env.REACT_APP_OASEBACKEND_API_URL}${backendUrls.getCompanyById}?companyId=${encodeURIComponent(companyId)}`;
    this.log.writeLog(logPrefix + "URL: " + url);

    const accessToken = localStorage.getItem("loginToken");
    if (!accessToken) {
      this.log.writeLog(logPrefix + "No access token available.");
      throw new Error("Authentication required");
    }

    try {
      const response = await axios.get<CompanyResponseItem>(url, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      this.log.writeLog(logPrefix + "Successfully fetched company by ID.");
      return response.data;
    } catch (error) {
      this.log.writeLog(logPrefix + "Error fetching company by ID: " + error.message);
      throw error;
    }
  }
}

export default new DataService();
